import React, { useEffect, useState } from "react";
import Show from "../Show";
import {faDesktop, faHeadset, faMobileAlt, faUser, faCircle} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Format from "../../helpers/Format";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import "./style.scss";
import { MultipleDocuments } from "../../views/VideoCall/MultipleDocuments";
import { isMobileDevice } from "../../helpers/Utils";

interface ContributorData {
  document: string;
  name: string
}

interface Props {
  userType: string,
  contributorData: Array<any>
  attendance_name: string
  attendantName: string;
  isMobile?: boolean
  getUpdatedDocument: (document: string) => void;
  balcaoId: string;
  getData: (data: ContributorData[]) => void;
  status: 'stable' | 'unstable' | 'disconnected' | undefined;
}

export default function ContributorGeneralInfos (props: Props) {
  const [contributorName, setContributorName] = useState('');
  const [totalTributes, setTotalTributes] = useState(0);
  const [totalInscriptions, setTotalinscriptions] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0.00);
  const [totalBalanceRefis, setTotalBalanceRefis] = useState(0.00);
  const [refisEnabled, setRefisEnabled] = useState(false);

  useEffect(() => {
    updateData();
  },[props.contributorData, props.attendance_name]);

  const updateData = () => {
    if(props.contributorData){
      setContributorName(props.attendance_name);
      setTotalTributes(props.contributorData[1]);
      setTotalinscriptions(props.contributorData[2]);
      setRefisEnabled(props.contributorData[5]);
      if(props.contributorData[5]){
        setTotalBalance(props.contributorData[4]);
        setTotalBalanceRefis(props.contributorData[3]);
      }else if (props.contributorData[3]){
        setTotalBalance(props.contributorData[3]);
      }else {
        setTotalBalance(0);
      }
    };
  }

  const renderStatus = () => {
    switch (props.status) {
      case 'stable':
        return (
          <div className="status stable">
            <FontAwesomeIcon icon={faCircle} />
            <span>Conexão estável</span>
          </div>
        );
      case 'unstable':
        return (
          <div className="status unstable">
            <FontAwesomeIcon icon={faCircle} />
            <span>Conexão instável, aguarde estabilização...</span>
          </div>
        );
      case 'disconnected':
        return (
          <div className="status disconnected">
            <FontAwesomeIcon icon={faCircle} />
            <span>Desconectado, aguarde reconexão...</span>
          </div>
        );
      default:
        return (
          <div className="status unknown">
            <i className="icon unknown-icon"></i>
            <FontAwesomeIcon icon={faCircle} />
            <span>Conectando...</span>
          </div>
        );
    }
  };

  const setUserType = (type: string) => {
    if(type == 'attendant'){
      return(
        <div className="contributorPersonalData">
          <div className="d-flex align-items-center">
            <span className="label">Contribuinte</span>
            <div className="originAccess ml-1 px-1">
              {props.isMobile ? (
                <>
                  <FontAwesomeIcon className="fa-xs mr-1" icon={faMobileAlt} />
                  <span>via mobile</span>
                </>
              ) : (
                <>
                  <FontAwesomeIcon className="fa-xs mr-1" icon={faDesktop} />
                  <span>via desktop</span>
                </>
              )}
            </div>
          </div>
          { contributorName ? <span className="contributorName">{contributorName}</span> : <Skeleton count={1} /> }
          {renderStatus()}
        </div>
      );
    } else if(type == 'contributor') {
      return(
        <div className="contributorPersonalData">
          <span className="label">Atendente</span>
          { props.attendantName ? <span className="contributorName">{props.attendantName}</span> : <Skeleton count={1} /> }
          {renderStatus()}
        </div>
      );
    }
  }

  const updateContributorDocument = (document: string) => {
    props.getUpdatedDocument(document);
  }

  const getCompleteInfos = (contributorInfos: {document: string; name: string;}[]) => {
    props.getData(contributorInfos)
  }

  return (
    <div className="row containerInfo">
      <div className="col-12 col-sm-4">
        <div className="contributorPersonalInfo">
          <div className={`contributorIcon ${props.userType === 'contributor' && 'attendantBg'}`}>
            {
              props.userType == 'attendant' ? (
                <FontAwesomeIcon icon={faUser} />
              ) : (
                <FontAwesomeIcon icon={faHeadset} />
              )
            }
          </div>
          {setUserType(props.userType)}
        </div>
      </div>
      <Show if={!isMobileDevice()}>
        <div className="col-12 col-sm-8">
          <MultipleDocuments
            balcaoId={props.balcaoId}
            updateDocument={updateContributorDocument}
            getContributorInfos={getCompleteInfos}
            userType={props.userType}
          />
          <div className="row">
            <div className="col-6 col-sm-3 totalTributes">
              <span className="tributesLabel">Total de tributos</span>
                {totalTributes ? <span>{totalTributes} {`${setTotalTributes.length > 1 ? "tributos" : "tributo"}`}</span> : <Skeleton count={1} /> }
            </div>
            <div className="col-6 col-sm-3 totalTributes">
              <span className="tributesLabel">Total de inscrições</span>
              { totalInscriptions ? <span>{totalInscriptions} {`${totalInscriptions > 1 ? "inscrições" : "inscrição"}`}</span> :  <Skeleton count={1} /> }
            </div>
            <div className="col-12 col-sm-3 totalTributes">
              <span className="tributesLabel">Saldo devedor geral</span>
              { totalBalance ? <span>{Format.currency(totalBalance)}</span> : <Skeleton count={1} /> }
            </div>
            <Show if={refisEnabled}>
              <div className="col-12 col-sm-3 totalbalanceRefis">
                <span>
                  Saldo devedor geral
                  <span className="refisLabel">REFIS</span>
                </span>
                <span className="refisvalue">{Format.currency(totalBalanceRefis)}</span>
              </div>
            </Show>
          </div>
        </div>
      </Show>
      <span className="bottomLine" />
    </div>
  )
}
