import React, { useEffect, useState } from 'react';
import './style.scss';
import { useHistory } from 'react-router-dom';
import BalcaoVirtualService from '../../../services/BalcaoVirtualService';
import { MultiSelect } from '../../../components/MultiSelect';
import AtendimentoService from '../../../services/AtendimentoService';
import { clearSessionData, randomToken } from '../../../helpers/Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { cpfOrCnpjMask } from '../../../helpers/Masks';
import {attendanceReasonData, debtKnowledgeData, resolutionData  } from './optionsData'
import Modal from '../../../components/Modal';
import Select from '../../../components/Select';

export interface Option {
  value: string;
  label: string;
}

interface ContributorData {
  document: string;
  name: string
}

type FormProps = {
  balcaoId: string;
  attendantId: any;
  contributorData: Array<any>
  contributorDocument: string | null;
  resetStyles: () => void;
  contributorCompleteData: ContributorData[] | undefined;
  attendanceName: string
}

export function Form(props: FormProps) {
  const history = useHistory();
  const [contributor, setContributor] = useState<string | undefined>();
  const [reasons, setReasons] = useState<Option[]>([]);
  const [debtKnowledge, setDebtKnowledge] = useState<Option[]>([]);
  const [resolution, setResolution] = useState<Option[]>([]);
  const [loading, setLoading] = useState(false);
  const [finallyAttendance, setFinallyAttendance] = useState(false);
  const [enableButton, setEnableButton] = useState(false);
  const [isNetworkIssue, setNetworkIssue] = useState(false);
  const [modal, setModal] = useState<boolean>(false);
  const [currentReason, setCurrentReason] = useState('');

  useEffect(() => {
    if(props.contributorData){
      setContributor(props.contributorData[0]);
    }
  }, []);

  useEffect(() => {
    formValidate()
  }, [reasons, debtKnowledge, resolution]);

  const unavailableReasons = [
    { label: "Almoço", value: 1 },
    { label: "Intervalo", value: 2 },
    { label: "Fim de expediente", value: 3}
  ];

  const handleTitle = () => {
    if (!contributor || (contributor && finallyAttendance)){
      return (
        <></>
      )
    }
    return (
      <div className='attendantFormMessage'>
        <span className='loadingText'>
          O atendimento com
          <span className='loadingTextName'> {props.attendanceName} </span>
          foi encerrado.
        </span>
        <span className='loadingText'>Preencha os campos de acordo com o atendimento de cada CPF/CNPJ consultado:</span>
      </div>
    )
    ;
  }

  const createJitsiUrl = () => {
    const token = randomToken();
    const url = (`https://jitsi.sefin.fortaleza.ce.gov.br/${token}`);
    return {url, token};
  };

  const handleFinalizarAtendimento = (type: 'available' | 'unavailable', reason?: string) => {
    clearSessionData();
    if (type === 'available'){
      const idBalcaoSelecionado =  parseInt(props.balcaoId);
      const statusSelecionado = type;
      const {url, token} = createJitsiUrl();
      history.push('/area_atendente');
      BalcaoVirtualService.changeStatus(statusSelecionado, idBalcaoSelecionado, url)
        .then(() => {
          localStorage.removeItem('document');
          window.open(`video_call/${idBalcaoSelecionado}/${token}`, "_blank");
          window.close();
        });
    } else {
      BalcaoVirtualService.changeStatus("unavailable", parseInt(props.balcaoId), '', [], undefined, undefined, reason);
      props.resetStyles();
      history.push('/area_atendente');
    }
  }

  const handleSaveAttendance = async(arrayData: string, data: { [key: string]: any } = {}) => {
    const document = arrayData;
    const reasonList = data[arrayData].reason.map((reasons: string) => reasons)
    const debt_knowledge = data[arrayData].knowledge.map((knowledge: string) => knowledge).filter((value: string) => value !== '')
    const solutions = data[arrayData].solution.map((solution: string) => solution).filter((value: string) => value !== '')
    const attendanceId = localStorage.getItem('attendance_id') || '';
    setLoading(true)
    try {
      AtendimentoService
        .salvarInformacoesAtendimento(props.attendantId, document, reasonList, debt_knowledge, solutions, attendanceId)
        .then((response) => {
          if(response){
            setLoading(false)
            setFinallyAttendance(true);
          }
        })
    } catch (error) {
      console.error(error);
    }
  }

  const finallyForm = () => (
    <div className='finallyAttendanceform'>
      <div className='finallyTitle'>Deseja ficar <b>disponível</b> ou <b>indisponível</b> para novos atendimentos?</div>
      <div className='finallyFormButtonContainer'>
        <button className='available' onClick={() => (handleFinalizarAtendimento('available'))}>Ficar disponível</button>
        <button className='unavailable' onClick={() => setModal(true)}>Ficar indisponível</button>
      </div>
    </div>
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target)
    const data: { [key: string]: any } = {};
    formData.forEach((value, name) => {
      const documentIndex = name.match(/^\d+/);
      if(documentIndex) {
        const identifier = name.replace(documentIndex[0], '');
        if(data[documentIndex[0]]) {
          if(data[documentIndex[0]][identifier]) {
            data[documentIndex[0]][identifier].push(value);
          } else {
            data[documentIndex[0]][identifier] = [value];
          }
        } else {
          data[documentIndex[0]] = { [identifier]: [value] };
        }
      }
    })
    for(const document in data) {
      handleSaveAttendance(document, data)
    }
  }

  const formValidate = () => {
    const networkIssue = reasons.find((reason: Option) => reason.value === 'Problema de conexão')
    if (networkIssue) {
      setEnableButton(true);
      setNetworkIssue(true);
    } else {
      const validate = Boolean(reasons.length !== 0 && debtKnowledge.length !== 0 && resolution.length)
      setEnableButton(validate);
      setNetworkIssue(false);
    }
  }

  const renderForm = () => {
    return(
      <>
        {
          !finallyAttendance
          ?<>
            <div className='labelsContainer'>
              <span className='optionLabel'>Contribuinte</span>
              <span className='optionLabel'>Motivo do atendimento*</span>
              <span className='optionLabel'>{`Conhecimento do débito ${isNetworkIssue ? '' : '*'}`}</span>
              <span className='optionLabel'>{`Resolução ${isNetworkIssue ? '' : '*'}`}</span>
            </div>
            <span className='endLine' />
            <form className='formBody' onSubmit={(e) => handleSubmit(e)}>
              <div className='optionsContainer'>
                {props.contributorCompleteData && props.contributorCompleteData.map((contributor, i) => (
                  <>
                    <div className='formContributorDataContainer'>
                      <div className='formContributorInfos'>
                        <span className='formContributorDoc'>{cpfOrCnpjMask(contributor.document).mask()}</span>
                        <span className='formContributorName'>{contributor.name}</span>
                      </div>
                      <div className='formOptions'>
                        <MultiSelect
                          onChangeSelected={(options) => setReasons(options)}
                          optionType={attendanceReasonData}
                          contributorDocument={contributor.document}
                          indexOf={`${contributor.document}reason`}
                          placeholder={'Selecione o motivo'}
                        />
                      </div>
                      <div className='formOptions'>
                        <MultiSelect
                          onChangeSelected={(options) => setDebtKnowledge(options)}
                          optionType={debtKnowledgeData}
                          contributorDocument={contributor.document}
                          indexOf={`${contributor.document}knowledge`}
                          placeholder={'Selecione o conhecimento'}
                        />
                      </div>
                      <div className='formOptions'>
                        <MultiSelect
                          onChangeSelected={(options) => setResolution(options)}
                          optionType={resolutionData}
                          contributorDocument={contributor.document}
                          indexOf={`${contributor.document}solution`}
                          placeholder={'Selecione a resolução'}
                        />
                      </div>
                    </div>
                    { props.contributorCompleteData && props.contributorCompleteData.length -1 != i && <span className='formSplitLine' />}
                  </>
                ))}
              </div>
              {loading
                ? <button disabled className={'sendFormButton'}>
                    <strong>
                      <FontAwesomeIcon icon={faCircleNotch} spin className="fa-spin-fast" />
                    </strong>
                  </button>
                :
                !enableButton
                ? <button disabled className={'sendFormButton'}>
                    Enviar Relatório de Atendimento
                  </button>
                : <input
                    className={'sendFormButtonEnabled'}
                    type='submit'
                    value='Enviar Relatório de Atendimento'
                  />
              }
            </form>
          </>
          : <>
              {finallyForm()}
            </>
        }
      </>
    )
  }

  return (
    <div className="form-container">
      {
        <Modal small show={modal} onHide={() => setModal(false)} centered={true}>
          <div className="modalContentBody">
            <div className="modalContent">
              <div className="modalInput">
                <div className="contentModal">
                  <span>Qual é o motivo da sua indisponibilidade?</span>
                </div>
                <div>
                  <Select
                    options={unavailableReasons}
                    onSelect={(e: string) => setCurrentReason(e)}
                  />
                </div>
              </div>
              <div className={"confirmDiv"}>
                <button
                  disabled={currentReason === ''}
                  onClick={() => handleFinalizarAtendimento('unavailable', currentReason)}
                  className={`${currentReason === '' ? "disabled" : "enabled"}`}
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </Modal>
      }
      <div className="containerItems">
        <div className="form">
          <div className='title'> {handleTitle()} </div>
          {contributor ? renderForm() : finallyForm()}
        </div>
      </div>
    </div>
  );
}
